// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../../../common/style/img/edit.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".skyxplore-game-population-citizen-name-wrapper {\r\n    font-size: 20px;\r\n    padding: 10px;\r\n}\r\n\r\n.skyxplore-game-population-citizen-rename-button {\r\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n    background-size: cover;\r\n    width: 22px;\r\n    height: 22px;\r\n}\r\n\r\n.skyxplore-game-population-citizen-name-save-button {\r\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n    background-size: cover;\r\n    width: 27px;\r\n    height: 27px;\r\n    color: transparent;\r\n}", "",{"version":3,"sources":["webpack://./src/modules/skyxplore/game/navigation/population/citizen/name/citizen_name.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,yDAAuE;IACvE,sBAAsB;IACtB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,yDAAuE;IACvE,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".skyxplore-game-population-citizen-name-wrapper {\r\n    font-size: 20px;\r\n    padding: 10px;\r\n}\r\n\r\n.skyxplore-game-population-citizen-rename-button {\r\n    background-image: url(\"../../../../../../../common/style/img/edit.png\");\r\n    background-size: cover;\r\n    width: 22px;\r\n    height: 22px;\r\n}\r\n\r\n.skyxplore-game-population-citizen-name-save-button {\r\n    background-image: url(\"../../../../../../../common/style/img/edit.png\");\r\n    background-size: cover;\r\n    width: 27px;\r\n    height: 27px;\r\n    color: transparent;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
