// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../../common/style/img/edit.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#notebook-pin-groups {\r\n    border-bottom: 1px solid white;\r\n}\r\n\r\n#notebook-manage-pin-groups-button {\r\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n    background-repeat: no-repeat;\r\n    background-position: center;\r\n    background-size: cover;\r\n    width: 27px;\r\n\r\n}\r\n\r\n.notebook-selected-pin-group {\r\n    border-color: lime;\r\n}", "",{"version":3,"sources":["webpack://./src/modules/notebook/index/notebook_modules/pin/groups/pin_groups.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;AAClC;;AAEA;IACI,yDAAoE;IACpE,4BAA4B;IAC5B,2BAA2B;IAC3B,sBAAsB;IACtB,WAAW;;AAEf;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["#notebook-pin-groups {\r\n    border-bottom: 1px solid white;\r\n}\r\n\r\n#notebook-manage-pin-groups-button {\r\n    background-image: url(\"../../../../../../common/style/img/edit.png\");\r\n    background-repeat: no-repeat;\r\n    background-position: center;\r\n    background-size: cover;\r\n    width: 27px;\r\n\r\n}\r\n\r\n.notebook-selected-pin-group {\r\n    border-color: lime;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
