// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../common/style/img/chat.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#skyxplore-game-toggle-chat-button{\r\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n    background-size: cover;\r\n    width: 2.25rem;\r\n    height: 2.25rem;\r\n    float: right;\r\n}\r\n\r\n#skyxplore-game-toggle-chat-button.unread{\r\n    border-color: red;\r\n}", "",{"version":3,"sources":["webpack://./src/modules/skyxplore/game/chat/toggle_button/toggle_chat_button.css"],"names":[],"mappings":"AAAA;IACI,yDAAiE;IACjE,sBAAsB;IACtB,cAAc;IACd,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["#skyxplore-game-toggle-chat-button{\r\n    background-image: url(\"../../../../../common/style/img/chat.png\");\r\n    background-size: cover;\r\n    width: 2.25rem;\r\n    height: 2.25rem;\r\n    float: right;\r\n}\r\n\r\n#skyxplore-game-toggle-chat-button.unread{\r\n    border-color: red;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
