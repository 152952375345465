const PageName = {
    MAP: "map",
    SOLAR_SYSTEM: "solar-system",
    PLANET: "planet",
    UPGRADE_BUILDING: "upgrade-building",
    MODIFY_SURFACE: "modify-surface",
    POPULATION: "population",
    STORAGE: "storage",
}

export default PageName;