const CitizenAssignmentType = {
    IDLE: "IDLE",
    CONSTRUCTION: "CONSTRUCTION",
    DECONSTRUCTION: "DECONSTRUCTION",
    REST: "REST",
    TERRAFORMATION: "TERRAFORMATION",
    PRODUCTION_ORDER: "PRODUCTION_ORDER",
    STORAGE_SETTING: "STORAGE_SETTING",
}

export default CitizenAssignmentType;