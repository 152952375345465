import { Endpoint, RequestMethod } from "../dao";

export const NOTEBOOK_GET_CATEGORY_TREE = new Endpoint(RequestMethod.GET, "/api/notebook/category/tree");
export const NOTEBOOK_GET_PINNED_ITEMS = new Endpoint(RequestMethod.GET, "/api/notebook/item/pinned");
export const NOTEBOOK_GET_CHILDREN_OF_CATEGORY = new Endpoint(RequestMethod.GET, "/api/notebook/category/children");
export const NOTEBOOK_CREATE_CATEGORY = new Endpoint(RequestMethod.PUT, "/api/notebook/category");
export const NOTEBOOK_CREATE_TEXT = new Endpoint(RequestMethod.PUT, "/api/notebook/text");
export const NOTEBOOK_GET_TEXT = new Endpoint(RequestMethod.GET, "/api/notebook/text/{listItemId}");
export const NOTEBOOK_EDIT_TEXT = new Endpoint(RequestMethod.POST, "/api/notebook/text/{listItemId}");
export const NOTEBOOK_DELETE_LIST_ITEM = new Endpoint(RequestMethod.DELETE, "/api/notebook/item/{listItemId}");
export const NOTEBOOK_ARCHIVE_ITEM = new Endpoint(RequestMethod.POST, "/api/notebook/item/{listItemId}/archive");
export const NOTEBOOK_PIN_LIST_ITEM = new Endpoint(RequestMethod.POST, "/api/notebook/item/{listItemId}/pin");
export const NOTEBOOK_CLONE_LIST_ITEM = new Endpoint(RequestMethod.POST, "/api/notebook/{listItemId}/clone");
export const NOTEBOOK_GET_LIST_ITEM = new Endpoint(RequestMethod.GET, "/api/notebook/list-item/{listItemId}");
export const NOTEBOOK_EDIT_LIST_ITEM = new Endpoint(RequestMethod.POST, "/api/notebook/item/{listItemId}");
export const NOTEBOOK_CREATE_LINK = new Endpoint(RequestMethod.PUT, "/api/notebook/link");
export const NOTEBOOK_CREATE_ONLY_TITLE = new Endpoint(RequestMethod.PUT, "/api/notebook/only-title");
export const NOTEBOOK_CREATE_CHECKLIST = new Endpoint(RequestMethod.PUT, "/api/notebook/checklist");
export const NOTEBOOK_GET_CHECKLIST = new Endpoint(RequestMethod.GET, "/api/notebook/checklist/{listItemId}");
export const NOTEBOOK_UPDATE_CHECKLIST_ITEM_STATUS = new Endpoint(RequestMethod.POST, "/api/notebook/checklist/item/{checklistItemId}/status");
export const NOTEBOOK_UPDATE_CHECKLIST_ITEM_CONTENT = new Endpoint(RequestMethod.POST, "/api/notebook/checklist/item/{checklistItemId}/content");
export const NOTEBOOK_DELETE_CHECKLIST_ITEM = new Endpoint(RequestMethod.DELETE, "/api/notebook/checklist/item/{checklistItemId}");
export const NOTEBOOK_EDIT_CHECKLIST = new Endpoint(RequestMethod.POST, "/api/notebook/checklist/{listItemId}");
export const NOTEBOOK_CHECKLIST_DELETE_CHECKED = new Endpoint(RequestMethod.DELETE, "/api/notebook/checklist/{listItemId}/checked");
export const NOTEBOOK_ORDER_CHECKLIST_ITEMS = new Endpoint(RequestMethod.POST, "/api/notebook/checklist/{listItemId}/order");
export const NOTEBOOK_ADD_CHECKLIST_ITEM = new Endpoint(RequestMethod.PUT, "/api/notebook/checklist/{listItemId}/item");
export const NOTEBOOK_CREATE_TABLE = new Endpoint(RequestMethod.PUT, "/api/notebook/table");
export const NOTEBOOK_GET_TABLE = new Endpoint(RequestMethod.GET, "/api/notebook/table/{listItemId}");
export const NOTEBOOK_EDIT_TABLE = new Endpoint(RequestMethod.POST, "/api/notebook/table/{listItemId}");
export const NOTEBOOK_CREATE_CHECKLIST_TABLE = new Endpoint(RequestMethod.PUT, "/api/notebook/checklist-table");
export const NOTEBOOK_GET_CHECKLIST_TABLE = new Endpoint(RequestMethod.GET, "/api/notebook/checklist-table/{listItemId}");
export const NOTEBOOK_TABLE_SET_ROW_STATUS = new Endpoint(RequestMethod.POST, "/api/notebook/table/row/{rowId}/status");
export const NOTEBOOK_TABLE_SET_CHECKBOX_COLUMN_STATUS = new Endpoint(RequestMethod.POST, "/api/notebook/table/column/{columnId}/status");
export const NOTEBOOK_EDIT_CHECKLIST_TABLE = new Endpoint(RequestMethod.POST, "/api/notebook/checklist-table/{listItemId}");
export const NOTEBOOK_CREATE_IMAGE = new Endpoint(RequestMethod.PUT, "/api/notebook/image");
export const NOTEBOOK_CREATE_FILE = new Endpoint(RequestMethod.PUT, "/api/notebook/file");
export const NOTEBOOK_SEARCH = new Endpoint(RequestMethod.POST, "/api/notebook/item/search");
export const NOTEBOOK_MOVE_LIST_ITEM = new Endpoint(RequestMethod.POST, "/api/notebook/{listItemId}/move");
export const NOTEBOOK_CREATE_CUSTOM_TABLE = new Endpoint(RequestMethod.PUT, "/api/notebook/custom-table");
export const NOTEBOOK_GET_CUSTOM_TABLE = new Endpoint(RequestMethod.GET, "/api/notebook/custom-table/{listItemId}");
export const NOTEBOOK_TABLE_DELETE_CHECKED = new Endpoint(RequestMethod.DELETE, "/api/notebook/table/{listItemId}/checked");
export const NOTEBOOK_GET_PIN_GROUPS = new Endpoint(RequestMethod.GET, "/api/notebook/pin-group");
export const NOTEBOOK_CREATE_PIN_GROUP = new Endpoint(RequestMethod.PUT, "/api/notebook/pin-group");
export const NOTEBOOK_DELETE_PIN_GROUP = new Endpoint(RequestMethod.DELETE, "/api/notebook/pin-group/{pinGroupId}");
export const NOTEBOOK_RENAME_PIN_GROUP = new Endpoint(RequestMethod.POST, "/api/notebook/pin-group/{pinGroupId}");
export const NOTEBOOK_ADD_ITEM_TO_PIN_GROUP = new Endpoint(RequestMethod.POST, "/api/notebook/pin-group/{pinGroupId}/add/{listItemId}");
export const NOTEBOOK_REMOVE_ITEM_FROM_PIN_GROUP = new Endpoint(RequestMethod.DELETE, "/api/notebook/pin-group/{pinGroupId}/remove/{listItemId}");
export const NOTEBOOK_PIN_GROUP_OPENED = new Endpoint(RequestMethod.PUT, "/api/notebook/pin-group/{pinGroupId}");
