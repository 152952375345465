// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./img/en.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./img/hu.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#language-selector .language {\r\n    width: 36px;\r\n    height: 36px;\r\n    background-position: center;\r\n    background-size: 36px;\r\n}\r\n\r\n#language-selector .language.current {\r\n    border-color: orange;\r\n}\r\n\r\n#language-selector .language.en {\r\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n}\r\n\r\n#language-selector .language.hu {\r\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\r\n}", "",{"version":3,"sources":["webpack://./src/common/component/language_selector/language_selector.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,2BAA2B;IAC3B,qBAAqB;AACzB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,yDAAqC;AACzC;;AAEA;IACI,yDAAqC;AACzC","sourcesContent":["#language-selector .language {\r\n    width: 36px;\r\n    height: 36px;\r\n    background-position: center;\r\n    background-size: 36px;\r\n}\r\n\r\n#language-selector .language.current {\r\n    border-color: orange;\r\n}\r\n\r\n#language-selector .language.en {\r\n    background-image: url(\"./img/en.png\");\r\n}\r\n\r\n#language-selector .language.hu {\r\n    background-image: url(\"./img/hu.png\");\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
