const OpenedPageType = {
    CATEGORY: "CATEGORY",
    TEXT: "TEXT",
    LINK: "LINK",
    ONLY_TITLE: "ONLY_TITLE",
    CHECKLIST: "CHECKLIST",
    TABLE: "TABLE",
    CHECKLIST_TABLE: "CHECKLIST_TABLE",
    IMAGE: "IMAGE",
    FILE: "FILE",
    SEARCH: "SEARCH",
    CUSTOM_TABLE: "CUSTOM_TABLE",
    MANAGE_PIN_GROUPS: "MANAGE_PIN_GROUPS",
}

export default OpenedPageType;