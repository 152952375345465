import { Endpoint, RequestMethod } from "../dao";

export const VILLANY_ATESZ_CREATE_CONTACT = new Endpoint(RequestMethod.PUT, "/api/villany-atesz/contact");
export const VILLANY_ATESZ_EDIT_CONTACT = new Endpoint(RequestMethod.POST, "/api/villany-atesz/contact/{contactId}");
export const VILLANY_ATESZ_DELETE_CONTACT = new Endpoint(RequestMethod.DELETE, "/api/villany-atesz/contact/{contactId}");
export const VILLANY_ATESZ_GET_CONTACTS = new Endpoint(RequestMethod.GET, "/api/villany-atesz/contact");
export const VILLANY_ATESZ_CREATE_STOCK_CATEGORY = new Endpoint(RequestMethod.PUT, "/api/villany-atesz/stock/category");
export const VILLANY_ATESZ_GET_STOCK_CATEGORIES = new Endpoint(RequestMethod.GET, "/api/villany-atesz/stock/category");
export const VILLANY_ATESZ_DELETE_STOCK_CATEGORY = new Endpoint(RequestMethod.DELETE, "/api/villany-atesz/stock/category/{stockCategoryId}");
export const VILLANY_ATESZ_EDIT_STOCK_CATEGORY = new Endpoint(RequestMethod.POST, "/api/villany-atesz/stock/category/{stockCategoryId}");
export const VILLANY_ATESZ_CREATE_STOCK_ITEM = new Endpoint(RequestMethod.PUT, "/api/villany-atesz/stock/item");
export const VILLANY_ATESZ_GET_STOCK_ITEMS = new Endpoint(RequestMethod.GET, "/api/villany-atesz/stock/item");
export const VILLANY_ATESZ_CREATE_CART = new Endpoint(RequestMethod.PUT, "/api/villany-atesz/cart");
export const VILLANY_ATESZ_GET_CARTS = new Endpoint(RequestMethod.GET, "/api/villany-atesz/cart");
export const VILLANY_ATESZ_ADD_TO_CART = new Endpoint(RequestMethod.POST, "/api/villany-atesz/cart");
export const VILLANY_ATESZ_GET_CART = new Endpoint(RequestMethod.GET, "/api/villany-atesz/cart/{cartId}");
export const VILLANY_ATESZ_DELETE_CART = new Endpoint(RequestMethod.DELETE, "/api/villany-atesz/cart/{cartId}");
export const VILLANY_ATESZ_FINALIZE_CART = new Endpoint(RequestMethod.POST, "/api/villany-atesz/cart/{cartId}");
export const VILLANY_ATESZ_GET_STOCK_ITEMS_FOR_CATEGORY = new Endpoint(RequestMethod.GET, "/api/villany-atesz/stock/category/{stockCategoryId}");
export const VILLANY_ATESZ_STOCK_ACQUIRE = new Endpoint(RequestMethod.POST, "/api/villany-atesz/stock/acquire");
export const VILLANY_ATESZ_STOCK_INVENTORY_GET_ITEMS = new Endpoint(RequestMethod.GET, "/api/villany-atesz/stock/inventory");
export const VILLANY_ATESZ_STOCK_INVENTORY_EDIT_NAME = new Endpoint(RequestMethod.POST, "/api/villany-atesz/stock/inventory/{stockItemId}/name");
export const VILLANY_ATESZ_STOCK_INVENTORY_EDIT_SERIAL_NUMBER = new Endpoint(RequestMethod.POST, "/api/villany-atesz/stock/inventory/{stockItemId}/serial-number");
export const VILLANY_ATESZ_STOCK_INVENTORY_EDIT_BAR_CODE = new Endpoint(RequestMethod.POST, "/api/villany-atesz/stock/inventory/{stockItemId}/bar-code");
export const VILLANY_ATESZ_STOCK_INVENTORY_EDIT_IN_CAR = new Endpoint(RequestMethod.POST, "/api/villany-atesz/stock/inventory/{stockItemId}/in-car");
export const VILLANY_ATESZ_STOCK_INVENTORY_EDIT_IN_STORAGE = new Endpoint(RequestMethod.POST, "/api/villany-atesz/stock/inventory/{stockItemId}/in-storage");
export const VILLANY_ATESZ_STOCK_INVENTORY_EDIT_CATEGORY = new Endpoint(RequestMethod.POST, "/api/villany-atesz/stock/inventory/{stockItemId}/category");
export const VILLANY_ATESZ_STOCK_INVENTORY_EDIT_INVENTORIED = new Endpoint(RequestMethod.POST, "/api/villany-atesz/stock/inventory/{stockItemId}/inventoried");
export const VILLANY_ATESZ_STOCK_INVENTORY_EDIT_MARKED_FOR_ACQUISITION = new Endpoint(RequestMethod.POST, "/api/villany-atesz/stock/inventory/{stockItemId}/marked-for-acquisition");
export const VILLANY_ATESZ_MOVE_STOCK_TO_CAR = new Endpoint(RequestMethod.POST, "/api/villany-atesz/stock/item/{stockItemId}/to-car");
export const VILLANY_ATESZ_MOVE_STOCK_TO_STORAGE = new Endpoint(RequestMethod.POST, "/api/villany-atesz/stock/item/{stockItemId}/to-storage");
export const VILLANY_ATESZ_REMOVE_FROM_CART = new Endpoint(RequestMethod.DELETE, "/api/villany-atesz/cart/{cartId}/item/{stockItemId}");
export const VILLANY_ATESZ_DELETE_STOCK_ITEM = new Endpoint(RequestMethod.DELETE, "/api/villany-atesz/stock/item/{stockItemId}");
export const VILLANY_ATESZ_FIND_STOCK_ITEM_BY_BAR_CODE = new Endpoint(RequestMethod.POST, "/api/villany-atesz/stock/item/bar-code");
export const VILLANY_ATESZ_FIND_BAR_CODE_BY_STOCK_ITEM_ID = new Endpoint(RequestMethod.GET, "/api/villany-atesz/stock/item/{stockItemId}/bar-code");
export const VILLANY_ATESZ_CART_EDIT_MARGIN = new Endpoint(RequestMethod.POST, "/api/villany-atesz/cart/{cartId}/margin");
export const VILLANY_ATESZ_INDEX_TOTAL_STOCK_VALUE = new Endpoint(RequestMethod.GET, "/api/villany-atesz/index/total-value/stock");
export const VILLANY_ATESZ_INDEX_TOTAL_TOOLBOX_VALUE = new Endpoint(RequestMethod.GET, "/api/villany-atesz/index/total-value/toolbox");
export const VILLANY_ATESZ_INDEX_GET_STOCK_ITEMS_MARKED_FOR_ACQUISITION = new Endpoint(RequestMethod.GET, "/api/villany-atesz/index/stock-item/marked-for-acquisition");
export const VILLANY_ATESZ_RESET_INVENTORIED = new Endpoint(RequestMethod.POST, "/api/villany-atesz/stock/inventory/reset-inventoried");
export const VILLANY_ATESZ_GET_ACQUISITION_DATES = new Endpoint(RequestMethod.GET, "/api/villany-atesz/acquisition");
export const VILLANY_ATESZ_GET_ACQUISITIONS = new Endpoint(RequestMethod.GET, "/api/villany-atesz/acquisition/{acquiredAt}");
export const VILLANY_ATESZ_GET_STOCK_ITEM = new Endpoint(RequestMethod.GET, "/api/villany-atesz/stock/item/{stockItemId}");
export const VILLANY_ATESZ_GET_TOOLS = new Endpoint(RequestMethod.GET, "/api/villany-atesz/tool");
export const VILLANY_ATESZ_CREATE_TOOL = new Endpoint(RequestMethod.PUT, "/api/villany-atesz/tool");
export const VILLANY_ATESZ_SET_TOOL_STATUS = new Endpoint(RequestMethod.POST, "/api/villany-atesz/tool/{toolId}");
export const VILLANY_ATESZ_DELETE_TOOL = new Endpoint(RequestMethod.DELETE, "/api/villany-atesz/tool/{toolId}");
export const VILLANY_ATESZ_GET_TOOL_TYPES = new Endpoint(RequestMethod.GET, "/api/villany-atesz/tool/type");
export const VILLANY_ATESZ_GET_STORAGE_BOXES = new Endpoint(RequestMethod.GET, "/api/villany-atesz/toolbox/storage-box");
export const VILLANY_ATESZ_TOOLBOX_INVENTORY_EDIT_NAME = new Endpoint(RequestMethod.POST, "/api/villany-atesz/tool/inventory/{toolId}/name");
export const VILLANY_ATESZ_TOOLBOX_INVENTORY_EDIT_BRAND = new Endpoint(RequestMethod.POST, "/api/villany-atesz/tool/inventory/{toolId}/brand");
export const VILLANY_ATESZ_TOOLBOX_INVENTORY_EDIT_COST = new Endpoint(RequestMethod.POST, "/api/villany-atesz/tool/inventory/{toolId}/cost");
export const VILLANY_ATESZ_TOOLBOX_INVENTORY_EDIT_INVENTORIED = new Endpoint(RequestMethod.POST, "/api/villany-atesz/tool/inventory/{toolId}/inventoried");
export const VILLANY_ATESZ_TOOLBOX_INVENTORY_EDIT_ACQUIRED_AT = new Endpoint(RequestMethod.POST, "/api/villany-atesz/tool/inventory/{toolId}/acquired-at");
export const VILLANY_ATESZ_TOOLBOX_INVENTORY_EDIT_WARRANTY_EXPIRES_AT = new Endpoint(RequestMethod.POST, "/api/villany-atesz/tool/inventory/{toolId}/warranty-expires-at");
export const VILLANY_ATESZ_TOOLBOX_INVENTORY_EDIT_SCRAPPED_AT = new Endpoint(RequestMethod.POST, "/api/villany-atesz/tool/inventory/{toolId}/scrapped-at");
export const VILLANY_ATESZ_TOOLBOX_INVENTORY_EDIT_STATUS = new Endpoint(RequestMethod.POST, "/api/villany-atesz/tool/inventory/{toolId}/status");
export const VILLANY_ATESZ_TOOLBOX_INVENTORY_EDIT_TOOL_TYPE = new Endpoint(RequestMethod.POST, "/api/villany-atesz/tool/inventory/{toolId}/tool-type");
export const VILLANY_ATESZ_TOOLBOX_INVENTORY_EDIT_STORAGE_BOX = new Endpoint(RequestMethod.POST, "/api/villany-atesz/tool/inventory/{toolId}/storage-box");
export const VILLANY_ATESZ_TOOLBOX_INVENTORY_RESET_INVENTORIED = new Endpoint(RequestMethod.POST, "/api/villany-atesz/tool/inventory/reset-inventoried");
export const VILLANY_ATESZ_DELETE_TOOL_TYPE = new Endpoint(RequestMethod.DELETE, "/api/villany-atesz/tool/tool-type/{toolTypeId}");
export const VILLANY_ATESZ_EDIT_TOOL_TYPE = new Endpoint(RequestMethod.POST, "/api/villany-atesz/tool/tool-type/{toolTypeId}");
export const VILLANY_ATESZ_DELETE_STORAGE_BOX = new Endpoint(RequestMethod.DELETE, "/api/villany-atesz/tool/storage-box/{storageBoxId}");
export const VILLANY_ATESZ_EDIT_STORAGE_BOX = new Endpoint(RequestMethod.POST, "/api/villany-atesz/tool/storage-box/{storageBoxId}");